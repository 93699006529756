<template>
  <v-card class="ml-10 mb-10 pa-5 resultCard">
    <v-row>
      <v-col cols="4" class="text-center pa-5" style="align-self: center">
        <p>La teva nota global</p>
        <h2 style="font-size: 40pt">{{ getTotalScore() }}%</h2>
        <h4 v-if="totalScore <25">La seva empresa requereix d'un treball previ a l'inici del seu procés d'internacionacionalització. Si ens deixa l'acompanyarem en aquest camí.</h4>
        <h4 v-else-if="totalScore >=25 && totalScore <50">Ànims! Tot i que a la seva empresa li calen certs canvis abans d'iniciar el seu procés d'internacionalització, vendre a l'estranger és molt factible.</h4>
        <h4 v-else-if="totalScore >=50 && totalScore <75">Felicitats! La seva empresa compta amb bona part dels actius necessaris per iniciar la seva internacionalització amb èxit. Pot contactar amb la Cambra de Comerç per a més informació</h4>
        <h4 v-else-if="totalScore >=75 && totalScore <=100">Enhorabona! La seva empresa compta amb bona part dels actius necessaris per iniciar la seva internacionalització amb èxit. Pot contactar amb la Cambra de Comerç per a més informació </h4>
      </v-col>
      <v-col cols="8" class="pa-5">
        <v-row style="align-items: center">
          <v-col cols="5">
            Anàlisi del punt de partida
          </v-col>
          <v-col cols="7">
            <v-progress-linear :value="getScore(0,partialArray[0].points)" height="20">
              <strong>{{getScore(0,partialArray[0].points)}}%</strong>
            </v-progress-linear>
          </v-col>
          <v-col cols="5">
            Nivell de planificació
          </v-col>
          <v-col cols="7">
            <v-progress-linear color="orange" :value="getScore(1,partialArray[1].points)" height="20">
              <strong>{{getScore(1,partialArray[1].points)}}%</strong>
            </v-progress-linear>
          </v-col>
          <v-col cols="5">
            Característiques del producte/servei
          </v-col>
          <v-col cols="7">
            <v-progress-linear color="green" :value="getScore(2,partialArray[2].points)" height="20">
              <strong>{{getScore(2,partialArray[2].points)}}%</strong>
            </v-progress-linear>
          </v-col>
          <v-col cols="5">
            Accés a nous clients/mercats
          </v-col>
          <v-col cols="7">
            <v-progress-linear color="pink" :value="getScore(3,partialArray[3].points)" height="20">
              <strong>{{getScore(3,partialArray[3].points)}}%</strong>
            </v-progress-linear>
          </v-col>
          <v-col cols="5" class="mt-5">
            <a :href="'https://diagnosiexport.cambralleida.org/api/resumeDoc.php?tok='+ $route.params.token" target="_blank">
              <v-btn elevation="2" class="noprint" color="#ba0c2f" data-html2canvas-ignore="true" dark > Descarregar informe PDF </v-btn>
            </a>
          </v-col>
          <v-col cols="6" class="mt-5">
            <v-btn elevation="2" class="noprint" color="#ba0c2f" data-html2canvas-ignore="true" dark> Enviar informe PDF per correu electrónic </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "globalResult",
  props: ['partialArray','generatePDF','loadPDF'],
  data() {
    return {
      partialScore: 0,
      load:false,
      totalScore:0
    }
  },
  mounted() {
    this.getTotalScore()
  },
  methods:{
    print(){
      window.print();
    },
    getScore(index,score) {
      switch (index) {
        case 0:
          return this.partialScore = ((score * 20 / 16)*100/20).toFixed(0)
        case 1:
          return this.partialScore = ((score * 20 / 12)*100/20).toFixed(0)
        case 2:
          return this.partialScore = ((score * 20 / 20)*100/20).toFixed(0)
        case 3:
          return this.partialScore = ((score * 20 / 10)*100/20).toFixed(0)
      }
    },
    getTotalScore(){
      let part1 = (this.partialArray[0].points* 20 / 16)*100/20;
      let part2 = (this.partialArray[1].points* 20 / 16)*100/20;
      let part3 = (this.partialArray[2].points* 20 / 16)*100/20;
      let part4 = (this.partialArray[3].points* 20 / 16)*100/20;
      return this.totalScore =  ((part1*0.2+part2*0.3+part3*0.2+part4*0.2)/0.9).toFixed(0);
    }
  }

}
</script>

<style scoped>
.resultCard{
  background: white;
}
@media print{
  .noprint{
    display: none;
  }
}
</style>