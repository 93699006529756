<template>
  <v-container>
    <v-row justify="space-around" align="center" class="align-content-center" ref="document" >
        <v-col cols="12" v-if="loaded" >
          <h1 class="ma-10 title">Resultats de l'autodiagnosi</h1>
          <global-result :partial-array="partialScore" :load-p-d-f="loadPDF" :generate-p-d-f="generatePDF"/>
          <partial-result v-for="(pscore,index) in partialScore" v-bind:key="index" :index="index"
                          itle="Anàlisi del punt de partida"
                          :color="getColor(index)" :score="pscore.points"/>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PartialResult from "../components/results/partialResult";
import GlobalResult from "../components/results/global";
import html2pdf from 'html2pdf.js'

export default {
  name: "Results",
  components: {GlobalResult, PartialResult},
  data() {
    return {
      partialScore: [],
      score: 0,
      loaded: false,
      loadPDF: false
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      let formData = new FormData();
      formData.set('session', this.$session.id())
      formData.set('token',this.$route.params.token)
      let url = this.env_url + 'getresults.php'
      this.axios.post(url, formData).then(res => {
        this.score = res.data.score;
        this.partialScore.push({points: res.data.p1})
        this.partialScore.push({points: res.data.p2})
        this.partialScore.push({points: res.data.p3})
        this.partialScore.push({points: res.data.p4})
        this.loaded = true
      });
    },
    generatePDF(){
      this.loadPDF = true;
      html2pdf(this.$refs.document, {
        margin: 1,
        filename: 'autodiagnosi_cambra.pdf',
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        pagebreak:    { mode: 'css' }
      })
      var that = this;
      setTimeout(() => that.loadPDF = false, 1500);
    },
    getColor(index) {
      switch (index) {
        case 0:
          return 'blue';
        case 1:
          return 'orange';
        case 2:
          return 'green';
        case 3:
          return 'pink';
      }
    }
  }
}
</script>

<style scoped>

</style>